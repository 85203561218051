import React from 'react'
import Card from '../components/Card'
import Grid from '../components/Grid'
import Header from '../components/Header'
import Wrapper from '../styles/Wrapper'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout title="Artikelen" content="Vanwege mijn onstilbare honger naar kennis over voeding, pluis ik graag uitdagende of controversiële onderwerpen helemaal uit. Daarnaast deel ik dit uiteraard graag met de rest van de wereld!">
    <Header />

    <Wrapper>
      <h1 css={{ textAlign: 'center' }}>Artikelen</h1>

      <p
        css={{
          margin: '0 auto 4rem',
          maxWidth: 840,
          textAlign: 'center',
        }}
      >
        Vanwege mijn onstilbare honger naar kennis over voeding, pluis ik graag uitdagende of controversiële onderwerpen helemaal uit. Daarnaast deel ik dit uiteraard graag met de rest van de wereld! Ook kan ik tijdens een consult niet álles uitgebreid aan bod komen, dus is het fijn om naar mijn eigen artikelen te kunnen verwijzen.
      </p>

      <Grid>
        {data.allMarkdownRemark.edges.map(article => (
          <Card
            key={article.node.frontmatter.path}
            title={article.node.frontmatter.title}
            meta={`Gepubliceerd op ${article.node.frontmatter.date}`}
            content={article.node.frontmatter.description}
            moreLabel="Lees volledig artikel"
            slug={article.node.frontmatter.path}
          />
        ))}
      </Grid>
    </Wrapper>
  </Layout>
)

export const query = graphql`
  query Articles {
    allMarkdownRemark(
      filter: { frontmatter: { path: { regex: "/artikelen/.+/" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD MMMM YYYY", locale: "nl")
            description
            path
          }
        }
      }
    }
  }
`
